import Swiper from 'swiper/bundle';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';
import ImagesLoaded from 'imagesloaded';
//import PlayBeautifully from './play-beautifully.js';
import * as Matter from 'matter-js';

// register GSAP plugins
gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

(function($) {
  // easy jQuery reference variables
  const $document = $(document);
  const $html = $('html');
  const $body = $('body');
  const $header = $('.site-header');
  const $isSM = window.matchMedia('(min-width: 52.125rem)');
  const $isMD = window.matchMedia('(min-width: 80rem)');

  // request animation frame
  var $flags = {
    scrolled: false,
    pauseScrolling: false,
    resized: false,
    resizedWidth: false,
    resizedHeight: false,
    pauseResizing: false,
  };

  var $timers = {
    scroll: -1,
    resize: -1,
    delta: 16.67 //  (1/60fps) / 1000ms = 16.67ms per frame
  };

  var $window = {
    width: window.innerWidth,
    lastWidth: window.innerWidth,
    height: window.innerHeight,
    lastHeight: window.innerHeight,
    scrollX: window.pageXOffset,
    lastScrollX: 0,
    scrollY: window.pageYOffset,
    lastScrollY: 0,
    scrollingDown: true
  };

  // prepare scroll function
  window.addEventListener('scroll', function() {
    $flags.scrolled = true;
    $window.scrollX = window.pageXOffset;
    $window.scrollY = window.pageYOffset;

    if($window.scrollY <= $window.lastScrollY) {
      $window.scrollingDown = false;
    } else {
      $window.scrollingDown = true;
    }
    $window.lastScrollY = $window.scrollY;

    if($timers.scroll !== -1) {
      clearTimeout($timers.scroll);
    }

    $timers.scroll = window.setTimeout(function() {
      $flags.scrolled = false;
    }, $timers.delta);
  });

  // prepare resize function
  window.addEventListener('resize', function() {
    $flags.resized = true;
    $window.width = window.innerWidth;
    $window.height = window.innerHeight;

    if($window.width !== $window.lastWidth) {
      $flags.resizedWidth = true;
    }
    $window.lastWidth = $window.width;

    if($window.height !== $window.lastHeight) {
      $flags.resizedHeight = true;
    }
    $window.lastHeight = $window.height;

    if($timers.resize !== -1) {
      clearTimeout($timers.resize);
    }

    $timers.resize = window.setTimeout(function() {
      $flags.resized = false;
      $flags.resizedWidth = false;
      $flags.resizedHeight = false;
    }, $timers.delta);
  });

  var requestScrollAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.scrolled) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resized) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeWidthAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resizedWidth) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeHeightAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resizedHeight) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  // set js cookie
  function setCookie(name, value, days) {
    var expires = '';

    if(days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = '; expires='+date.toGMTString();
    }

    document.cookie = name+'='+value+expires+'; path=/';
  }

  // get js cookie
  function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');

    for(var i=0; i < ca.length; i++) {
      var c = ca[i];

      while(c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if(c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length,c.length);
      }
    }

    return false;
  }

  // check if iOS
  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  // check if Safari
  function safari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  // announcement bar
  var $announcementBar = $('.announcement-bar');
  if($announcementBar.length > 0) {
    var $announcementCookie = getCookie($announcementBar.attr('id'));
    if($announcementCookie) {
      $announcementBar.remove();
    } else {
      $html.addClass('state--show-announcement-bar');
    }

    // close announcement bar
    $('.announcement-bar_close').click(function(e) {
      e.preventDefault();

      $html.removeClass('state--show-announcement-bar');
      $announcementBar.remove();
      setCookie($announcementBar.attr('id'), true, 7);
    });
  }

  // add loaded class to body when ready
  $body.imagesLoaded(function() {
    $body.addClass('loaded');

    // parallax headings
    $('[data-parallax-headings]').each(function() {
      var $section = $(this);
      var $headings = $section.find('h1,h2,p,.text-component_buttons');

      const $tl = gsap.timeline({
        scrollTrigger: {
          trigger: $section[0],
          start: 'top center',
        }
      });

      $tl.fromTo($headings, {
        opacity: 0,
        y: 48
      }, {
        opacity: 1,
        y: 0,
        stagger: 0.1,
        duration: 1
      });
    });

    // pathway divider
    if($('#pathway-divider').length) {
      create({
        player: '#pathway-divider',
        mode: 'scroll',
        container: document.querySelector('.pathway-divider'),
        actions: [
          {
            visibility: [0.1, 1.0],
            type: 'seek',
            frames: [0, 191],
          },
        ]
      });
    }
  });

  // check header
  function checkHeader() {
    if($window.scrollY > 0) {
      $header.addClass('show-border');
    } else {
      $header.removeClass('show-border');
    }
  }
  requestScrollAnimation(checkHeader);

  // open mobile menu
  $document.on('click', '.mobile-menu_open', function(e) {
    e.preventDefault();

    $html.addClass('state--menu-open');
  });

  // close mobile menu
  $document.on('click', '.mobile-menu_close', function(e) {
    e.preventDefault();

    $html.removeClass('state--menu-open');
  });

  // parallax tennis balls
  $('.tennis-ball.parallax').each(function() {
    var $ball = $(this);
    var $yPercent = (typeof $ball.data('y') !== 'undefined') ? $ball.data('y'): 0;
    var $xPercent = (typeof $ball.data('x') !== 'undefined') ? $ball.data('x'): 0;

    const $tl = gsap.timeline({
      scrollTrigger: {
        trigger: 'body',
        start: 'top 0%',
        end: 'top -100%',
        scrub: 1
      }
    });

    $tl.to($ball[0], {
      yPercent: $yPercent,
      xPercent: $xPercent,
      duration: 1
    });
  });

  // circle backgrounds
  $('.circle-bg').each(function() {
    var $circle = $(this);
    var $start = ($circle.hasClass('circle-bg--top')) ? 'top 100%' : 'bottom 100%';
    var $end = ($circle.hasClass('circle-bg--top')) ? 'top 75%' : 'bottom 75%';

    const $tl = gsap.timeline({
      scrollTrigger: {
        trigger: $circle[0],
        start: $start,
        end: $end,
        scrub: 1
      }
    });

    $tl.fromTo($circle[0], {
      scaleX: 12
    }, {
      scaleX: 1,
      duration: 1
    });
  });

  // testimonials swiper
  if($('.testimonial-component_swiper').length > 0) {
    var $testimonialsSwiper = new Swiper('.testimonial-component_swiper', {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: '.testimonial-component_swiper .swiper-button-next',
        prevEl:  '.testimonial-component_swiper .swiper-button-prev',
      }
    });
  }

  // toggle accordions
  $document.on('click', '.accordion-component_toggle', function(e) {
    e.preventDefault();

    var $toggle = $(this);
    var $accordion = $toggle.parent();
    var $content = $accordion.find('.accordion-component_content-wrapper');
    var $contentHeight = $accordion.find('.accordion-component_content').outerHeight();
    var $duration = $contentHeight * 0.0004;
    if($duration < 0.3) $duration = 0.3;

    if($accordion.hasClass('accordion-component--open')) {
      gsap.fromTo($content, {
        height: $contentHeight
      }, {
        height: 0,
        duration: $duration,
        ease: 'power1.inOut',
        onComplete: function() {
          $accordion.removeClass('accordion-component--open');
          $toggle.attr('aria-expanded', false);
          $content.attr('aria-hidden', true);
        }
      });
    } else {
      gsap.fromTo($content, {
        height: 0,
      }, {
        height: $contentHeight,
        duration: $duration,
        ease: 'power1.inOut',
        onComplete: function() {
          gsap.set($content, { height: 'auto' });
        }
      });

      $accordion.addClass('accordion-component--open');
      $toggle.attr('aria-expanded', false);
      $content.attr('aria-hidden', true);
    }
  });

  // toggle dropdowns
  $document.on('click', '.dropdown-component_toggle', function(e) {
    var $toggle = $(this);
    var $dropdown = $toggle.parent();
    var $content = $dropdown.find('.dropdown-component_content-wrapper');

    if($dropdown.hasClass('dropdown-component--open')) {
      $dropdown.removeClass('dropdown-component--open');
      $toggle.attr('aria-expanded', false);
      $content.attr('aria-hidden', true);
    } else {
      $dropdown.addClass('dropdown-component--open');
      $toggle.attr('aria-expanded', true);
      $content.attr('aria-hidden', false);
    }
  });

  // close dropdowns when clicking outside
  $document.on('click', function(e) {
    if($isSM.matches) {
      $('.dropdown-component--open').each(function() {
        var $dropdown = $(this);
        if(!$dropdown.is(e.target) && $dropdown.has(e.target).length === 0) {
          var $toggle = $dropdown.find('.dropdown-component_toggle');
          var $content = $dropdown.find('.dropdown-component_content-wrapper');
          $dropdown.removeClass('dropdown-component--open');
          $toggle.attr('aria-expanded', false);
          $content.attr('aria-hidden', true);
        }
      });
    }
  });

  // ajax category switching
  function switchCategory($url) {
    $('.archive-categories .chip--active').removeClass('chip--active');
    $('.archive-categories .chip[href$="'+$url+'"]').addClass('chip--active');

    $('<div>').load($url + ' #ajax', function() {
      var $div = $(this).find('.archive-content');
      $('#ajax').replaceWith($div);
    });
  }

  // category switching
  $document.on('click', '.archive-categories a', function(e) {
    e.preventDefault();

    var $category = $(this);
    var $url = $category.attr('href');
    switchCategory($url);
    history.pushState(true, null, $url);
  });

  // ajax post pagination
  $document.on('click', '.archive-pagination a', function(e) {
    e.preventDefault();

    var $nextLink = $(this);
    if($nextLink.hasClass('loading')) {
      return false;
    }
    $nextLink.addClass('loading');

    $('<div>').load($nextLink.attr('href') + ' #ajax', function() {
      var $div = $(this);
      var $posts = $div.find('.archive-list > li');
      var $pagination = $div.find('.archive-pagination');

      if($pagination.length>0) {
        $('.archive-pagination').replaceWith($pagination);
      } else {
        $('.archive-pagination').remove();
      }

      $('#ajax .archive-list').append($posts);
    });
  });

  // open coach overlay
  $document.on('click', '.coach-card', function(e) {
    e.preventDefault();

    var $content = $(this).find('.coach-card_content');
    $('.coach-overlay_content').html($content.html());

    $html.addClass('state--coach-overlay-open');
  });

  // close coach overlay
  $document.on('click', '.coach-overlay_close, .coach-overlay_mask', function(e) {
    e.preventDefault();

    $html.removeClass('state--coach-overlay-open');
  });

  // go to anchor
  function goToAnchor(url) {
    var $urlParts = url.split('#');

    if($urlParts.length > 1) {
      var $anchorTarget = $('#'+$urlParts[1]);

      if($anchorTarget.length) {
        var $y = $anchorTarget.offset().top;
        var $offsetY = $('.site-header').outerHeight();

        gsap.to(window, {
          duration: 0.4,
          scrollTo: {
            y: $y,
            offsetY: $offsetY
          }
        });

        return true;
      }
    }

    return false;
  }

  // anchor links
  $document.on('click', 'a[href^="#"]', function(e) {
    var $url = $(this).attr('href');
    if(goToAnchor($url)) {
      e.preventDefault();
    }
  });
  window.addEventListener('load', function() {
    goToAnchor(window.location.href, 0);
  });

  // validate recaptcha
  function validateCaptcha() {
    return new Promise((res, rej) => {
      if(typeof grecaptcha === 'undefined') {
        return res('');
      }

      grecaptcha.ready(() => {
        grecaptcha.execute('6LcqNaQqAAAAAAl7xciHHs_uivsm3Apc98rmhpdl', { action: 'submit' }).then((token) => {
          return res(token);
        });
      });
    });
  }

  // mailchimp subscription
  async function sendNewsletterForm($form) {
    var $newsletterComponent = $form.parent();
    var $formButton = $newsletterComponent.find('button[type="submit"]');

    $formButton.attr('disabled', true);
    $newsletterComponent.removeClass('newsletter-component--error').removeClass('newsletter-component--success');

    try {
      let token = await validateCaptcha();

      if(token === '') {
        $newsletterComponent.addClass('newsletter-component--error');
        $newsletterComponent.find('.newsletter-component_message').html('Missing recaptcha token.');
      }

      $.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {
          action: 'validate_recaptcha',
          recaptcha: token
        },
        cache: false,
        dataType: 'json',
        error: function(err) {
          console.log(err);
        },
        success: function(data) {
          if(data.result !== 'success') {
            $newsletterComponent.addClass('newsletter-component--error');
            $newsletterComponent.find('.newsletter-component_message').html(data.msg);
          } else {
            $.ajax({
              type: $form.attr('method'),
              url: 'https://summersmashtennis.us10.list-manage.com/subscribe/post-json?u=70e5b65feb64b87fb2aa5e454&id=11b5254dcd&c=?',
              data: $form.serialize(),
              cache: false,
              dataType: 'json',
              contentType: "application/json; charset=utf-8",
              error: function(err) {
                console.log(err);
              },
              success: function(data) {
                if(data.result !== 'success') {
                  $newsletterComponent.addClass('newsletter-component--error');
                  $newsletterComponent.find('.newsletter-component_message').html(data.msg.replace('0 - ', '').replace('1 - ', ''));
                } else {
                  $newsletterComponent.addClass('newsletter-component--success');
                  $newsletterComponent.find('.newsletter-component_message').html(data.msg);

                  setTimeout(function() {
                    $newsletterComponent.find('input[type="email"]').val('');
                    $newsletterComponent.removeClass('newsletter-component--error').removeClass('newsletter-component--success');
                  }, 10000);
                }
                $formButton.attr('disabled', false);
              }
            });
          }
        }
      });
    } catch(error) {
      $newsletterComponent.addClass('newsletter-component--error');
      $newsletterComponent.find('.newsletter-component_message').html('Looks like there was a problem: \n', error);
    }
  }

  var $newsletterForms = $('.newsletter-component form');
  $newsletterForms.each(function() {
    var $newsletterForm = $(this);

    $newsletterForm.bind('submit', function(e) {
      if(e) {
        e.preventDefault();
      }

      sendNewsletterForm($(this));
    });
  });

  // videos
  $document.on('click', '.media-component_video-play', function(e) {
    e.preventDefault();

    var $link = $(this);
    var $iframe = $link.parent().find('iframe');
    $iframe.attr('src', $link.attr('href'));
    $link.fadeOut();
  });

  // update class history
  function updateClassHistory() {
    if('URL' in window) {
      var $url = new URL(window.location);

      // remove all parameters
      $url.searchParams.delete('sort');
      $url.searchParams.delete('age-group[]');
      $url.searchParams.delete('lesson[]');
      $url.searchParams.delete('start-date');
      $url.searchParams.delete('class-day[]');
      $url.searchParams.delete('location[]');
      $url.searchParams.delete('show-available');

      // sort
      var $sort = $('input[name="sort"]:checked').val();
      $url.searchParams.set('sort', $sort);

      // age group
      $('input[name="age-group[]"]:checked').each(function() {
        var $ageGroup = $(this).val();
        if($ageGroup != '') {
          $url.searchParams.append('age-group[]', $ageGroup);
        }
      });

      // lessons
      $('input[name="lesson[]"]:checked').each(function() {
        var $lesson = $(this).val();
        if($lesson != '') {
          $url.searchParams.append('lesson[]', $lesson);
        }
      });

      // start date
      var $startDate = $('input[name="start-date"]').val();
      if($startDate !== '') {
        $url.searchParams.append('start-date', $startDate);
      }

      // days
      $('input[name="class-day[]"]:checked').each(function() {
        var $day = $(this).val();
        if($day != '') {
          $url.searchParams.append('class-day[]', $day);
        }
      });

      // locations
      $('input[name="location[]"]:checked').each(function() {
        var $location = $(this).val();
        if($location != '') {
          $url.searchParams.append('location[]', $location);
        }
      });

      // availability
      if($('input[name="show-available"]:checked').length > 0) {
        $url.searchParams.append('show-available', true);
      }


      window.history.pushState({}, '', $url);
    }
  }

  // class cards
  var $cardList = $('.archive-list--class');
  var $cards = $cardList.children('li');

  // sorting
  var sortList = Array.prototype.sort.bind($cards);
  function doSort() {
    var $sort = $('input[name="sort"]:checked').val();

    sortList(function(a, b) {
      var $aCard = $(a).find('.class-card');
      var $bCard = $(b).find('.class-card');

      var $aDate = $aCard.data('start-date');
      var $bDate = $bCard.data('start-date');

      if($aDate == $bDate) return 0;

      if($sort == 'asc') {
        if($aDate < $bDate) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if($aDate > $bDate) {
          return -1;
        } else {
          return 1;
        }
      }
    });

    $cardList.append($cards);
  }
  doSort();

  $document.on('change', 'input[name="sort"]', function() {
    doSort();
    updateClassHistory();
  });

  // filtering
  function doFilter() {
    // reset
    var $hasFilters = false;
    $('.clear-filters').hide();
    $('.no-results').hide();
    $cards.removeClass('filtered');

    // age group
    var $ageGroupFilters = [];
    $('input[name="age-group[]"]:checked').each(function() {
      var $ageGroup = $(this).val();
      if($ageGroup !== '') {
        $ageGroupFilters.push($ageGroup);
      }
    });

    if($ageGroupFilters.length > 0) {
      $hasFilters = true;

      $cards.filter(function() {
        var $card = $(this).find('.class-card');
        var $cardAgeGroups = $card.data('age-groups').split(',');

        var $found = false;
        for(var $i = 0; $i < $ageGroupFilters.length; $i++) {
          var $ageGroup = $ageGroupFilters[$i];
          if($cardAgeGroups.includes($ageGroup)) {
            $found = true;
          }
        }

        return !$found;
      }).addClass('filtered');
    }

    // START: disable unavailable filters
    var $lessons = new Set();
    $('.archive-list--class li:not(.filtered) .class-card').each(function() {
      var $card = $(this);
      $card.data('lessons').split(',').forEach(item => $lessons.add(item));
    });

    $('input[name="lesson[]"]').each(function() {
      var $input = $(this);
      var $inputVal = $input.val();

      if($lessons.has($inputVal)) {
        $input.closest('li').removeClass('removed');
      } else {
        $input.prop('checked', false);
        $input.closest('li').addClass('removed');
      }
    });

    var $classDays = new Set();
    $('.archive-list--class li:not(.filtered) .class-card').each(function() {
      var $card = $(this);
      $card.data('days').split(',').forEach(item => $classDays.add(item));
    });

    $('input[name="class-day[]"]').each(function() {
      var $input = $(this);
      var $inputVal = $input.val();

      if($classDays.has($inputVal)) {
        $input.closest('li').removeClass('disabled');
      } else {
        $input.prop('checked', false);
        $input.closest('li').addClass('disabled');
      }
    });

    var $locations = new Set();
    $('.archive-list--class li:not(.filtered) .class-card').each(function() {
      var $card = $(this);
      $card.data('locations').split(',').forEach(item => $locations.add(item));
    });

    $('input[name="location[]"]').each(function() {
      var $input = $(this);
      var $inputVal = $input.val();

      if($locations.has($inputVal)) {
        $input.closest('li').removeClass('removed');
      } else {
        $input.prop('checked', false);
        $input.closest('li').addClass('removed');
      }
    });
    // END: disable unavailable filters

    // lessons
    var $lessonFilters = [];
    $('input[name="lesson[]"]:checked').each(function() {
      var $lesson = $(this).val();
      if($lesson !== '') {
        $lessonFilters.push($lesson);
      }
    });

    if($lessonFilters.length > 0) {
      $hasFilters = true;

      $cards.filter(function() {
        var $card = $(this).find('.class-card');
        var $cardLessons = $card.data('lessons').split(',');

        var $found = false;
        for(var $i = 0; $i < $lessonFilters.length; $i++) {
          var $lesson = $lessonFilters[$i];
          if($cardLessons.includes($lesson)) {
            $found = true;
          }
        }

        return !$found;
      }).addClass('filtered');
    }

    // START: disable unavailable filters
    var $classDays = new Set();
    $('.archive-list--class li:not(.filtered) .class-card').each(function() {
      var $card = $(this);
      $card.data('days').split(',').forEach(item => $classDays.add(item));
    });

    $('input[name="class-day[]"]').each(function() {
      var $input = $(this);
      var $inputVal = $input.val();

      if($classDays.has($inputVal)) {
        $input.closest('li').removeClass('disabled');
      } else {
        $input.prop('checked', false);
        $input.closest('li').addClass('disabled');
      }
    });

    var $locations = new Set();
    $('.archive-list--class li:not(.filtered) .class-card').each(function() {
      var $card = $(this);
      $card.data('locations').split(',').forEach(item => $locations.add(item));
    });

    $('input[name="location[]"]').each(function() {
      var $input = $(this);
      var $inputVal = $input.val();

      if($locations.has($inputVal)) {
        $input.closest('li').removeClass('removed');
      } else {
        $input.prop('checked', false);
        $input.closest('li').addClass('removed');
      }
    });
    // END: disable unavailable filters

    // class days
    var $classDayFilters = [];
    $('input[name="class-day[]"]:checked').each(function() {
      var $classDay = $(this).val();
      if($classDay !== '') {
        $classDayFilters.push($classDay);
      }
    });

    if($classDayFilters.length > 0) {
      $hasFilters = true;

      $cards.filter(function() {
        var $card = $(this).find('.class-card');
        var $cardDays = $card.data('days').split(',');

        var $found = false;
        for(var $i = 0; $i < $classDayFilters.length; $i++) {
          var $classDay = $classDayFilters[$i];
          if($cardDays.includes($classDay)) {
            $found = true;
          }
        }

        return !$found;
      }).addClass('filtered');
    }

    // START: disable unavailable filters
    var $locations = new Set();
    $('.archive-list--class li:not(.filtered) .class-card').each(function() {
      var $card = $(this);
      $card.data('locations').split(',').forEach(item => $locations.add(item));
    });

    $('input[name="location[]"]').each(function() {
      var $input = $(this);
      var $inputVal = $input.val();

      if($locations.has($inputVal)) {
        $input.closest('li').removeClass('removed');
      } else {
        $input.prop('checked', false);
        $input.closest('li').addClass('removed');
      }
    });
    // END: disable unavailable filters

    // locations
    var $locationFilters = [];
    $('input[name="location[]"]:checked').each(function() {
      var $location = $(this).val();
      if($location !== '') {
        $locationFilters.push($location);
      }
    });

    if($locationFilters.length > 0) {
      $hasFilters = true;

      $cards.filter(function() {
        var $card = $(this).find('.class-card');
        var $cardLocations = $card.data('locations').split(',');

        var $found = false;
        for(var $i = 0; $i < $locationFilters.length; $i++) {
          var $location = $locationFilters[$i];
          if($cardLocations.includes($location)) {
            $found = true;
          }
        }

        return !$found;
      }).addClass('filtered');
    }

    // start date
    var $startDate = $('input[name="start-date"]').val()+' 00:00:00';

    if($startDate !== ' 00:00:00') {
      $hasFilters = true;

      $cards.filter(function() {
        var $card = $(this).find('.class-card');
        var $cardStartDate = $card.data('start-date');

        var $found = false;
        if($startDate < $cardStartDate) {
          $found = true;
        }

        return !$found;
      }).addClass('filtered');
    }

    // availability
    var $showAvailable = $('input[name="show-available"]:checked');
    if($showAvailable.length > 0) {
      $hasFilters = true;

      $cards.filter(function() {
        var $card = $(this).find('.class-card');
        var $cardStatus = $card.data('status');

        var $found = false;
        if($cardStatus != 'Full' && $cardStatus != 'Closed') {
          $found = true;
        }

        return !$found;
      }).addClass('filtered');
    }

    if($hasFilters) {
      $('.clear-filters').show();
    }

    if($('.archive-list--class li:not(.filtered)').length === 0) {
      $('.no-results').show();
    }
  }

  if($('.archive-list--class li').length > 0) {
    // START: disable unavailable age groups
    var $age_groups = new Set();
    $('.archive-list--class .class-card').each(function() {
      var $card = $(this);
      $card.data('age-groups').split(',').forEach(item => $age_groups.add(item));
    });

    $('input[name="age-group[]"]').each(function() {
      var $input = $(this);
      var $inputVal = $input.val();

      if(!$age_groups.has($inputVal)) {
        $input.prop('checked', false);
        $input.closest('li').addClass('removed');
      }
    });
    // END: disable unavailable age groups

    // initial filtering
    doFilter();
  }

  $document.on('change', 'input[name="age-group[]"],input[name="lesson[]"],input[name="start-date"],input[name="class-day[]"],input[name="location[]"],input[name="show-available"]', function() {
    doFilter();
    updateClassHistory();
  });

  // clear filters
  $document.on('click', '.clear-filters button, .no-results--class button', function(e) {
    e.preventDefault();

    $('input[name="age-group[]"]').prop('checked', false);
    $('input[name="lesson[]"]').prop('checked', false);
    $('input[name="start-date"]').val('');
    $('input[name="class-day[]"]').prop('checked', false);
    $('input[name="location[]"]').prop('checked', false);
    $('input[name="show-available"]').prop('checked', false);

    doFilter();
    updateClassHistory();
  });

  // update class filters after pop state
  function updateClassFilters() {
    if('URL' in window) {
      var $url = new URL(window.location);

      // reset properties
      $('input[name="sort"][value="asc"]').prop('checked', true);
      $('input[name="age-group[]"]').prop('checked', false);
      $('input[name="lesson[]"]').prop('checked', false);
      $('input[name="start-date"]').val('');
      $('input[name="class-day[]"]').prop('checked', false);
      $('input[name="location[]"]').prop('checked', false);
      $('input[name="show-available"]').prop('checked', false);

      $url.searchParams.forEach(function(value, key) {
        if(key === 'sort') {
          $('input[name="sort"][value="'+value+'"]').prop('checked', true);
        } else if(key === 'age-group[]') {
          $('input[name="age-group[]"][value="'+value+'"]').prop('checked', true);
        } else if(key === 'lesson[]') {
          $('input[name="lesson[]"][value="'+value+'"]').prop('checked', true);
        } else if(key === 'start-date') {
          $('input[name="start-date"]').val(value);
        } else if(key === 'class-day[]') {
          $('input[name="class-day[]"][value="'+value+'"]').prop('checked', true);
        } else if(key === 'location[]') {
          $('input[name="location[]"][value="'+value+'"]').prop('checked', true);
        }
      });

      doSort();
      doFilter();
    }
  }

  // watch for popstate
  window.onpopstate = function(e) {
    var $location = window.location;
    var $path = $location.pathname;

    if($path.startsWith('/blog/')) {
      switchCategory($path);
    } else if($path.startsWith('/classes/')) {
      updateClassFilters();
    }
  }

  // initialize matter js
  /*const containerElement = document.querySelector('#matter-container');
  const canvasElement = document.querySelector('#matter-canvas');
  if(containerElement && canvasElement) {
    var $playBeautifully = new PlayBeautifully({
      container: containerElement,
      canvas: canvasElement
    });
  }*/

  // matter-js module aliases
  var Engine = Matter.Engine,
      Events = Matter.Events,
      Render = Matter.Render,
      Runner = Matter.Runner,
      Bodies = Matter.Bodies,
      Composite = Matter.Composite,
      World = Matter.World,
      MouseConstraint = Matter.MouseConstraint,
      Mouse = Matter.Mouse;

  var matterContainer,
      matterCanvas,
      engine,
      render;

  function resetMatter() {
    Render.stop(render);
    World.clear(engine.world);
    Engine.clear(engine);

    if(render.canvas !== null) {
      render.canvas.getContext('2d').clearRect(0, 0, matterContainer.offsetWidth, matterContainer.offsetHeight);
      render.canvas = null;
      render.context = null;
      render.textures = {};
      setTimeout(setupMatter, 3000);
    }
  }

  // footer gravity balls
  function setupMatter() {

    // container values
    matterContainer = document.getElementById('matter-container');
    var width = matterContainer.offsetWidth;
    var height = matterContainer.offsetHeight;
    var offsetLeft = matterContainer.getBoundingClientRect().left;
    var offsetTop = matterContainer.getBoundingClientRect().top;

    // canvas values
    matterCanvas = document.getElementById('matter-canvas');
    matterCanvas.width = width;
    matterCanvas.height = height;

    // create an engine
    engine = Engine.create();

    // create a renderer
    render = Render.create({
      element: document.getElementById('matter-container'),
      canvas: matterCanvas,
      options: {
        width: width,
        height: height,
        background: 'transparent',
        wireframes: false,
        wireframesBackground: 'transparent',
        pixelRatio: window.devicePixelRatio
      },
      engine: engine
    });

    // number of tennis balls to product
    let count = 30;
    if(width < 768) {
      count = 20;
    }

    // size of tennis ball
    let ballRadius = 40;
    if(width > 1680) {
      ballRadius = (width / 1680) * ballRadius;
    }

    // tennis ball variations
    let textures = [
      '/wp-content/themes/summer-smash/assets/images/ball-1.svg',
      '/wp-content/themes/summer-smash/assets/images/ball-2.svg',
      '/wp-content/themes/summer-smash/assets/images/ball-3.svg',
      '/wp-content/themes/summer-smash/assets/images/ball-4.svg'
    ];

    let balls = [];
    for(var i = 0; i < count; i++) {
      var ballX = Math.floor(Math.random() * (width - (ballRadius * 2)));
      var ballY = 0;
      var texture = textures[Math.floor(Math.random() * 4)];

      let spriteScale = (ballRadius / 72);
      if(iOS() || safari()) spriteScale = spriteScale * window.devicePixelRatio;
      if(i > (count / 1.5)) {
        spriteScale = spriteScale + 0.004;
      } else if(i > (count / 3)) {
        spriteScale = spriteScale + 0.008;
      }

      var ball = Bodies.circle(ballX, ballY, ballRadius, {
        render: {
          sprite: {
            texture: texture,
            xScale: spriteScale,
            yScale: spriteScale
          }
        },
        restitution: 0.9
      });

      balls.push(ball);
      Composite.add(engine.world, ball);
    }

    // add static block for "playground"
    var play = document.querySelector('.play-beautifully');
    if(play !== null) {
      var baseWidth = width;
      var baseHeight = (height - play.getBoundingClientRect().top + offsetTop - (play.offsetHeight / 2));
      if($isMD.matches) {
        baseHeight = (height - play.getBoundingClientRect().top + offsetTop - play.offsetHeight) - 48;
      } else if($isSM.matches) {
        baseHeight = (height - play.getBoundingClientRect().top + offsetTop - play.offsetHeight) - 32;
      }
      var baseX = (baseWidth / 2);
      var baseY = height - (baseHeight / 2);

      var baseBody = Bodies.rectangle(baseX, baseY, baseWidth, baseHeight, {
        render: {
          fillStyle: 'transparent'
        },
        isStatic: true
      });
      Composite.add(engine.world, baseBody);

      if(!$isSM.matches) {
        var playWidth = (play.offsetWidth*0.395663957);
        var playHeight = (height - play.getBoundingClientRect().top + offsetTop);
        var playX = (baseWidth / 2);
        var playY = height - (playHeight / 2);

        var playBody = Bodies.rectangle(playX, playY, playWidth, playHeight, {
          render: {
            fillStyle: 'transparent'
          },
          isStatic: true
        });
        Composite.add(engine.world, playBody);
      }
    }

    // add static walls
    Composite.add(engine.world, [
      Bodies.rectangle((width/2), 0, width, 1, { // top
        render: {
          fillStyle: 'transparent'
        },
        isStatic: true
      }),
      Bodies.rectangle((width/2), height, width, 1, { // bottom
        render: {
          fillStyle: 'transparent'
        },
        isStatic: true
      }),
      Bodies.rectangle(0, (height/2), 1, height, { // left
        render: {
          fillStyle: 'transparent'
        },
        isStatic: true
      }),
      Bodies.rectangle(width, (height/2), 1, height, { //right
        render: {
          fillStyle: 'transparent'
        },
        isStatic: true
      }),
    ]);

    // add mouse control
    if(window.matchMedia('(hover: hover) and (pointer: fine)').matches) {
      var mouse = Mouse.create(render.canvas);
      mouse.pixelRatio = window.devicePixelRatio;
      var mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false
          }
        }
      });

      // allow mouse scrolling
      mouseConstraint.mouse.element.removeEventListener('mousewheel', mouseConstraint.mouse.mousewheel);
      mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', mouseConstraint.mouse.mousewheel);

      Composite.add(engine.world, mouseConstraint);
    }

    // run the renderer
    Render.run(render);

    // create runner
    var runner = Runner.create({
      isFixed: false
    });

    function startMatter() {
      if((window.innerHeight + Math.round(window.scrollY)) >= (document.body.offsetHeight - (window.innerHeight * 0.25))) {
        // run the engine
        Runner.run(runner, engine);

        window.removeEventListener('scroll', startMatter, false);
      }
    }
    window.addEventListener('scroll', startMatter, false);
  }

  $body.imagesLoaded(function() {
    setTimeout(setupMatter, 1000);
  });
  requestResizeWidthAnimation(resetMatter);
})(jQuery); // Fully reference jQuery after this point.
